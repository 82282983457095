import 'bootstrap'
require('../stylesheets/home.scss')

// Attention: Need to keep this in some random JS file for webpacker to recognize all images
const images = require.context('../images', true);

// Cookie consent
import * as cookieConsent from './cookie_consent';
cookieConsent;

$(function() {
    $(document).ready(function() {
        // will fade loading animation
        $(".lds-ellipsis").delay(300).fadeOut(200);
        // will fade loading background
        $("#loading").delay(600).fadeOut(400);

        var $navbar = $('.navbar:not(.permanent)');

        $('body').css('position', 'relative').scrollspy({ target: $navbar });

        // hide .navbar first
        $($navbar).hide();

        // fade in .navbar
        $(function () {
            $(window).scroll(function () {
                // set distance user needs to scroll before we fadeIn navbar
                if ($(this).scrollTop() > 100) {
                    $navbar.fadeIn();
                } else {
                    $navbar.fadeOut(100);
                }
            });


        });

    });

    $('a[href^="#"]').click(function(e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $(this.hash).offset().top - 100
        }, 1000);
    });

});